import {
	fetchReducer, fetchReducerRejected, fetchReducerFulfilled, fetchReducerErrorHide, fetchReducerMemRefInc, fetchReducerMemRefDec
} from './webserviceFetchReducer'

export function fetchDataIntoArrayItem(key, dataArray, fetchReducer, action) {
  const componentIdArrayIndex = dataArray.findIndex(x =>
    x.key.id === key.id
    && x.key.windowId === key.windowId
    && x.key.tabId === key.tabId
  )
  let newWSDataArray = [...dataArray]
  const newWSDataArrayElement = {key, ...fetchReducer(dataArray[componentIdArrayIndex], action)}
  if (componentIdArrayIndex !== -1) {
    newWSDataArray[componentIdArrayIndex] = newWSDataArrayElement
  } else {
    newWSDataArray.push(newWSDataArrayElement)
  }
  return newWSDataArray
}

export function changeMemRefCountInArrayItem(key, dataArray, fetchReducer, action) {
  const componentIdArrayIndex = dataArray.findIndex(x =>
    x.key.id === key.id
    && x.key.windowId === key.windowId
    && x.key.tabId === key.tabId
  )
  let newWSDataArray = [...dataArray]
  const newWSDataArrayElement = {key, ...fetchReducer(dataArray[componentIdArrayIndex], action)}

  if (newWSDataArrayElement.memoryReferenceCount <= 0) {
    newWSDataArray.splice(componentIdArrayIndex, 1);
  } else {
    if (componentIdArrayIndex !== -1) {
      newWSDataArray[componentIdArrayIndex] = newWSDataArrayElement
    } else {
      newWSDataArray.push(newWSDataArrayElement)
    }
  }

  return newWSDataArray
}

export function removeItemFromArray(key, wsDataArray) {
  return wsDataArray.filter(x => !(
    x.key.id === key.id
    && x.key.windowId === key.windowId
    && x.key.tabId === key.tabId
  ))
}

export const initialState = []
export default function reducer(state=initialState, action) {

	const split = action.type.split("/");
	// const action_type = split[0];
  const subaction_type = split[1];

	switch (subaction_type) {
    case undefined: {
      const { key } = action.payload
      return fetchDataIntoArrayItem(key, state, fetchReducer, action)
    }
    case "REJECTED": {
      const { key } = action.payload
      return fetchDataIntoArrayItem(key, state, fetchReducerRejected, action)
    }
    case "FULFILLED": {
      const { key } = action.payload
      return fetchDataIntoArrayItem(key, state, fetchReducerFulfilled, action)
    }
    case "ERROR_HIDE": {
      const { key } = action.payload
      return fetchDataIntoArrayItem(key, state, fetchReducerErrorHide, action)
    }
    case "MEM_RELEASE": {
      const { key } = action.payload
      return removeItemFromArray(key, state)
    }
    case "MEM_REF_INC": {
      const { key } = action.payload
      return changeMemRefCountInArrayItem(key, state, fetchReducerMemRefInc, action)
    }
    case "MEM_REF_DEC": {
      const { key } = action.payload
      return changeMemRefCountInArrayItem(key, state, fetchReducerMemRefDec, action)
    }
		default:
			return state;
	}
}
