export const initialState = {
	data: null,
	fetching: false,
	fetched: false,
	error: null,
	errorHidden: null,
  memoryReferenceCount: 0
}

export function fetchReducer(state=initialState, action) {
	return {
		...state,
		fetching: true,
		error: null,
		errorHidden: null,
	}
}

export function fetchReducerRejected(state=initialState, action) {
	// Przypisz message do errorMessage dla throw Exception(message)
	let { error } = action.payload
	if (error.errorMessage == null){
		error.errorMessage = error.message
	}

	return {
		...state,
		fetching: false,
		fetched: false,
		error,
		errorHidden: false,
	}
}

export function fetchReducerFulfilled(state=initialState, action) {
	let newData = action.payload.data

	return {
    ...state,
		data: newData,
		fetching: false,
		fetched: true,
		error: null,
		errorHidden: null
	}
}

export function fetchReducerErrorHide(state=initialState, action) {
	return {
		...state,
		errorHidden: true
	}
}

export function fetchReducerMemRefInc(state=initialState, action) {
  return {
	...state,
	fetching: false,
    memoryReferenceCount: state.memoryReferenceCount+1
  }
}

export function fetchReducerMemRefDec(state=initialState, action) {
  return {
    ...state,
    memoryReferenceCount: state.memoryReferenceCount-1
  }
}

