export function generateWebserviceFetchAction(actionType) {
    const split = actionType.split("/");
    // const action_type = split[0];
    const subaction_type = split[1];
    switch (subaction_type) {
        case "REJECTED": {
            return function (key, error) {
                return {
                    type: actionType,
                    payload: { key, error }
                }
            }
        }
        case "FULFILLED": {
            return function (key, data) {
                return {
                    type: actionType,
                    payload: { key, data }
                }
            }
        }
        case "ERROR_HIDE":
        case "MEM_RELEASE":
        case "MEM_REF_INC":
        case "MEM_REF_DEC": {
            return function (key) {
                return {
                    type: actionType,
                    payload: { key }
                }
            }
        }
        default: return null
    }
}
