import actions from './_actionTypes.js'
import { generateWebserviceFetchAction } from '../utils/actions.js'

export function wsUserLogIn(email, password, serverPoolId) {
  return {
    type: actions.WS_USER_LOG_IN,
    payload: { email, password, serverPoolId },
  }
}

export function wsUserLogInFulfilled(data, serverPoolId) {
  return {
    type: actions.WS_USER_LOG_IN_FULFILLED,
    payload: { data, serverPoolId }
  }
}

export function wsUserLogInRejected(error) {
  return {
    type: actions.WS_USER_LOG_IN_REJECTED,
    payload: { error }
  }
}

export function wsUserLogInErrorHide() {
	return {
		type: actions.WS_USER_LOG_IN_ERROR_HIDE,
		payload: {}
	}
}

export function wsUserLogOut() {
  return {
    type: actions.WS_USER_LOG_OUT,
  }
}

export function wsUserLogOutFulfilled(data, serverPoolId) {
  return {
    type: actions.WS_USER_LOG_OUT_FULFILLED,
    payload: { data, serverPoolId }
  }
}

export function wsUserLogOutRejected(error) {
  return {
    type: actions.WS_USER_LOG_OUT_REJECTED,
    payload: { error }
  }
}

export function wsUserLoggedIn(key) {
	return {
		type: actions.WS_USER_LOGGED_IN,
		payload: { key }
	}
}
export const wsUserLoggedInFulfilled = generateWebserviceFetchAction(actions.WS_USER_LOGGED_IN_FULFILLED)
export const wsUserLoggedInRejected = generateWebserviceFetchAction(actions.WS_USER_LOGGED_IN_REJECTED)
export const wsUserLoggedInErrorHide = generateWebserviceFetchAction(actions.WS_USER_LOGGED_IN_ERROR_HIDE)
export const wsUserLoggedInMemRelease = generateWebserviceFetchAction(actions.WS_USER_LOGGED_IN_MEM_RELEASE)
export const wsUserLoggedInMemRefInc = generateWebserviceFetchAction(actions.WS_USER_LOGGED_IN_MEM_REF_INC)
export const wsUserLoggedInMemRefDec = generateWebserviceFetchAction(actions.WS_USER_LOGGED_IN_MEM_REF_DEC)

export function wsUserRemindPassword(key, username, sendto) {
	return {
		type: actions.WS_USER_REMIND_PASSWORD,
		payload: { key, username, sendto }
	}
}
export const wsUserRemindPasswordFulfilled = generateWebserviceFetchAction(actions.WS_USER_REMIND_PASSWORD_FULFILLED)
export const wsUserRemindPasswordRejected = generateWebserviceFetchAction(actions.WS_USER_REMIND_PASSWORD_REJECTED)
export const wsUserRemindPasswordErrorHide = generateWebserviceFetchAction(actions.WS_USER_REMIND_PASSWORD_ERROR_HIDE)
export const wsUserRemindPasswordMemRelease = generateWebserviceFetchAction(actions.WS_USER_REMIND_PASSWORD_MEM_RELEASE)
export const wsUserRemindPasswordMemRefInc = generateWebserviceFetchAction(actions.WS_USER_REMIND_PASSWORD_MEM_REF_INC)
export const wsUserRemindPasswordMemRefDec = generateWebserviceFetchAction(actions.WS_USER_REMIND_PASSWORD_MEM_REF_DEC)

export function wsSetWsPathAndSP(wsPath, serverPoolId) {
	return {
		type: actions.WS_SET_WSPATH_AND_SP,
		payload: { wsPath, serverPoolId }
	}
}

export function wsSetWsPathAndSPFulfilled(wsPath, serverPoolId) {
  return {
    type: actions.WS_SET_WSPATH_AND_SP_FULFILLED,
    payload: { wsPath, serverPoolId }
  }
}

export function wsSetWsPathAndSPRejected(error) {
  return {
    type: actions.WS_SET_WSPATH_AND_SP_REJECTED,
    payload: { error }
  }
}

export function wsSetSessionId(sessionId) {
	return {
		type: actions.WS_SET_SESSION_ID,
		payload: { sessionId }
	}
}

export function wsSetSessionIdFulfilled(sessionId) {
  return {
    type: actions.WS_SET_SESSION_ID_FULFILLED,
    payload: { sessionId }
  }
}

export function wsSetSessionIdRejected(error) {
  return {
    type: actions.WS_SET_SESSION_ID_REJECTED,
    payload: { error }
  }
}

export function wsSetExternalServer(externalServerUrl) {
	return {
		type: actions.WS_SET_EXTERNAL_SERVER,
		payload: { externalServerUrl }
	}
}

export function wsSetExternalServerFulfilled(externalServerUrl) {
  return {
    type: actions.WS_SET_EXTERNAL_SERVER_FULFILLED,
    payload: { externalServerUrl }
  }
}

export function wsSetExternalServerRejected(error) {
  return {
    type: actions.WS_SET_EXTERNAL_SERVER_REJECTED,
    payload: { error }
  }
}

export function wsUserInfo(key) {
	return {
		type: actions.WS_USER_INFO,
		payload: { key }
	}
}
export const wsUserInfoFulfilled = generateWebserviceFetchAction(actions.WS_USER_INFO_FULFILLED)
export const wsUserInfoRejected = generateWebserviceFetchAction(actions.WS_USER_INFO_REJECTED)
export const wsUserInfoErrorHide = generateWebserviceFetchAction(actions.WS_USER_INFO_ERROR_HIDE)
export const wsUserInfoMemRelease = generateWebserviceFetchAction(actions.WS_USER_INFO_MEM_RELEASE)
export const wsUserInfoMemRefInc = generateWebserviceFetchAction(actions.WS_USER_INFO_MEM_REF_INC)
export const wsUserInfoMemRefDec = generateWebserviceFetchAction(actions.WS_USER_INFO_MEM_REF_DEC)

export function wsAccountList(key) {
	return {
		type: actions.WS_ACCOUNT_LIST,
		payload: { key }
	}
}
export const wsAccountListFulfilled = generateWebserviceFetchAction(actions.WS_ACCOUNT_LIST_FULFILLED)
export const wsAccountListRejected = generateWebserviceFetchAction(actions.WS_ACCOUNT_LIST_REJECTED)
export const wsAccountListErrorHide = generateWebserviceFetchAction(actions.WS_ACCOUNT_LIST_ERROR_HIDE)
export const wsAccountListMemRelease = generateWebserviceFetchAction(actions.WS_ACCOUNT_LIST_MEM_RELEASE)
export const wsAccountListMemRefInc = generateWebserviceFetchAction(actions.WS_ACCOUNT_LIST_MEM_REF_INC)
export const wsAccountListMemRefDec = generateWebserviceFetchAction(actions.WS_ACCOUNT_LIST_MEM_REF_DEC)

export function wsTrackList(key) {
	return {
		type: actions.WS_TRACK_LIST,
		payload: { key }
	}
}
export const wsTrackListFulfilled = generateWebserviceFetchAction(actions.WS_TRACK_LIST_FULFILLED)
export const wsTrackListRejected = generateWebserviceFetchAction(actions.WS_TRACK_LIST_REJECTED)
export const wsTrackListErrorHide = generateWebserviceFetchAction(actions.WS_TRACK_LIST_ERROR_HIDE)
export const wsTrackListMemRelease = generateWebserviceFetchAction(actions.WS_TRACK_LIST_MEM_RELEASE)
export const wsTrackListMemRefInc = generateWebserviceFetchAction(actions.WS_TRACK_LIST_MEM_REF_INC)
export const wsTrackListMemRefDec = generateWebserviceFetchAction(actions.WS_TRACK_LIST_MEM_REF_DEC)