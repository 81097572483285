export function passedTimeToString(timestamp) {
	let date_display = "";
	let t = parseInt(timestamp, 10);
	let days = parseInt(t / 86400, 10);
	t = t - (days * 86400);
	let hours = parseInt(t / 3600, 10);
	t = t - (hours * 3600);
	let minutes = parseInt(t / 60, 10);
	t = t - (minutes * 60);
	if (days) date_display += days + "d ";
	if (hours) date_display += hours + "h ";
	if (minutes) date_display += minutes + "m ";
	if (t) date_display += t + "s ";
	if (date_display === "") {
		return "0h 0m 0s"
	}
	return date_display;
}