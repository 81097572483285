import React from "react";
import {
  Tr,
  Td,
  Flex,
  Text,
  Progress,
  Icon,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaEllipsisV } from "react-icons/fa";

function TrackTableRow(props) {
  const { lp, date, trackTime, sleepTime, activityTime } = props;
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Tr>
      <Td /* minWidth={{ sm: "250px" }} */ pl="0px">
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {lp}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {date}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {trackTime}
        </Text>
      </Td>
      <Td>
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {sleepTime}
        </Text>
      </Td>
      <Td>
      <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {activityTime}
        </Text>
      </Td>
      {/* <Td>
        <Button p="0px" bg="transparent">
          <Icon as={FaEllipsisV} color="gray.400" cursor="pointer" />
        </Button>
      </Td> */}
    </Tr>
  );
}

export default TrackTableRow;
