const actionTypes = {

	//desktopUIActions
	WINDOW_OPEN: 'WINDOW_OPEN',
	WINDOW_OPEN_SAGA: 'WINDOW_OPEN_SAGA',
	WINDOW_CLOSE: 'WINDOW_CLOSE',
	WINDOW_BRING_TO_FRONT: 'WINDOW_BRING_TO_FRONT',
	WINDOW_MOVE: 'WINDOW_MOVE',
	WINDOW_STATE_SET: 'WINDOW_STATE_SET',
	TAB_WINDOW_STATE_SET: 'TAB_WINDOW_STATE_SET',
	WINDOW_STATE_REMOVE: 'WINDOW_STATE_REMOVE',
	WINDOW_SET_MOVE_IN_PROGRESS: 'WINDOW_SET_MOVE_IN_PROGRESS',
	WINDOW_CLOSE_ALL: 'WINDOW_CLOSE_ALL',
	WINDOW_MAXIMIZE: 'WINDOW_MAXIMIZE',

	TAB_NEW: 'TAB_NEW',
	TAB_RENAME: 'TAB_RENAME',
	TAB_MOVE: 'TAB_MOVE',
	TAB_SELECT: 'TAB_SELECT',
	TAB_CLOSE: 'TAB_CLOSE',
	TAB_CLOSE_ALL: 'TAB_CLOSE_ALL',
	TAB_CONTAINER_SIZE_SET: 'TAB_CONTAINER_SIZE_SET',

	CHANGE_GLOBAL_LANGUAGE: "CHANGE_GLOBAL_LANGUAGE",
	FILTER_OBJECTS: "FILTER_OBJECTS",
	FILTER_SERVICE_OBJECTS: "FILTER_SERVICE_OBJECTS",
	FILTER_DRIVERS: "FILTER_DRIVERS",
	FILTER_AREAS: "FILTER_AREAS",
	UI_DESKTOP_TEMPLATE_CHANGE: "UI_DESKTOP_TEMPLATE_CHANGE",
	UI_DESKTOP_ENABLE_LIGHT_MODE: "UI_DESKTOP_ENABLE_LIGHT_MODE",
	UI_DESKTOP_DASHBOARD_EDIT: "UI_DESKTOP_DASHBOARD_EDIT",
	UI_DESKTOP_WIDGET_ADD: "UI_DESKTOP_WIDGET_ADD",
	UI_DESKTOP_DASHBOARD_LOCAL_SAVE: "UI_DESKTOP_DASHBOARD_LOCAL_SAVE",
	UI_DESKTOP_RESET_STATE: "UI_DESKTOP_RESET_STATE",
	DISABLE_REFRESH: "DISABLE_REFRESH",
	ENABLE_REFRESH: "ENABLE_REFRESH",

	//webserviceActions
	WS_USER_LOG_IN: 'WS_USER_LOG_IN',
	WS_USER_LOG_IN_FULFILLED: 'WS_USER_LOG_IN_FULFILLED',
	WS_USER_LOG_IN_REJECTED: 'WS_USER_LOG_IN_REJECTED',
	WS_USER_LOG_IN_ERROR_HIDE: 'WS_USER_LOG_IN_ERROR_HIDE',
	WS_USER_LOG_OUT: 'WS_USER_LOG_OUT',
	WS_USER_LOG_OUT_FULFILLED: 'WS_USER_LOG_OUT_FULFILLED',
	WS_USER_LOG_OUT_REJECTED: 'WS_USER_LOG_OUT_REJECTED',

	WS_USER_LOGGED_IN: 'WS_USER_LOGGED_IN',
	WS_USER_LOGGED_IN_FULFILLED: 'WS_USER_LOGGED_IN/FULFILLED',
	WS_USER_LOGGED_IN_REJECTED: 'WS_USER_LOGGED_IN/REJECTED',
	WS_USER_LOGGED_IN_ERROR_HIDE: 'WS_USER_LOGGED_IN/ERROR_HIDE',
	WS_USER_LOGGED_IN_MEM_RELEASE: 'WS_USER_LOGGED_IN/MEM_RELEASE',
	WS_USER_LOGGED_IN_MEM_REF_INC: 'WS_USER_LOGGED_IN/MEM_REF_INC',
	WS_USER_LOGGED_IN_MEM_REF_DEC: 'WS_USER_LOGGED_IN/MEM_REF_DEC',

	WS_USER_REMIND_PASSWORD: 'WS_USER_REMIND_PASSWORD',
	WS_USER_REMIND_PASSWORD_FULFILLED: 'WS_USER_REMIND_PASSWORD/FULFILLED',
	WS_USER_REMIND_PASSWORD_REJECTED: 'WS_USER_REMIND_PASSWORD/REJECTED',
	WS_USER_REMIND_PASSWORD_ERROR_HIDE: 'WS_USER_REMIND_PASSWORD/ERROR_HIDE',
	WS_USER_REMIND_PASSWORD_MEM_RELEASE: 'WS_USER_REMIND_PASSWORD/MEM_RELEASE',
	WS_USER_REMIND_PASSWORD_MEM_REF_INC: 'WS_USER_REMIND_PASSWORD/MEM_REF_INC',
	WS_USER_REMIND_PASSWORD_MEM_REF_DEC: 'WS_USER_REMIND_PASSWORD/MEM_REF_DEC',

	WS_SET_WSPATH_AND_SP: 'WS_SET_WSPATH_AND_SP',
	WS_SET_WSPATH_AND_SP_FULFILLED: 'WS_SET_WSPATH_AND_SP/FULFILLED',
	WS_SET_WSPATH_AND_SP_REJECTED: 'WS_SET_WSPATH_AND_SP/REJECTED',

	WS_SET_SESSION_ID: 'WS_SET_SESSION_ID',
	WS_SET_SESSION_ID_FULFILLED: 'WS_SET_SESSION_ID/FULFILLED',
	WS_SET_SESSION_ID_REJECTED: 'WS_SET_SESSION_ID/REJECTED',

	WS_SET_EXTERNAL_SERVER: 'WS_SET_EXTERNAL_SERVER',
	WS_SET_EXTERNAL_SERVER_FULFILLED: 'WS_SET_EXTERNAL_SERVER/FULFILLED',
	WS_SET_EXTERNAL_SERVER_REJECTED: 'WS_SET_EXTERNAL_SERVER/REJECTED',

	WS_GET_CURRENT_TIMESTAMP: 'WS_GET_CURRENT_TIMESTAMP',
	WS_GET_CURRENT_TIMESTAMP_FULFILLED: 'WS_GET_CURRENT_TIMESTAMP/FULFILLED',
	WS_GET_CURRENT_TIMESTAMP_REJECTED: 'WS_GET_CURRENT_TIMESTAMP/REJECTED',

	WS_USER_INFO: 'WS_USER_INFO',
	WS_USER_INFO_FULFILLED: 'WS_USER_INFO/FULFILLED',
	WS_USER_INFO_REJECTED: 'WS_USER_INFO/REJECTED',
	WS_USER_INFO_ERROR_HIDE: 'WS_USER_INFO/ERROR_HIDE',
	WS_USER_INFO_MEM_RELEASE: 'WS_USER_INFO/MEM_RELEASE',
	WS_USER_INFO_MEM_REF_INC: 'WS_USER_INFO/MEM_REF_INC',
	WS_USER_INFO_MEM_REF_DEC: 'WS_USER_INFO/MEM_REF_DEC',

	WS_ACCOUNT_LIST: 'WS_ACCOUNT_LIST',
	WS_ACCOUNT_LIST_FULFILLED: 'WS_ACCOUNT_LIST/FULFILLED',
	WS_ACCOUNT_LIST_REJECTED: 'WS_ACCOUNT_LIST/REJECTED',
	WS_ACCOUNT_LIST_ERROR_HIDE: 'WS_ACCOUNT_LIST/ERROR_HIDE',
	WS_ACCOUNT_LIST_MEM_RELEASE: 'WS_ACCOUNT_LIST/MEM_RELEASE',
	WS_ACCOUNT_LIST_MEM_REF_INC: 'WS_ACCOUNT_LIST/MEM_REF_INC',
	WS_ACCOUNT_LIST_MEM_REF_DEC: 'WS_ACCOUNT_LIST/MEM_REF_DEC',

	WS_TRACK_LIST: 'WS_TRACK_LIST',
	WS_TRACK_LIST_FULFILLED: 'WS_TRACK_LIST/FULFILLED',
	WS_TRACK_LIST_REJECTED: 'WS_TRACK_LIST/REJECTED',
	WS_TRACK_LIST_ERROR_HIDE: 'WS_TRACK_LIST/ERROR_HIDE',
	WS_TRACK_LIST_MEM_RELEASE: 'WS_TRACK_LIST/MEM_RELEASE',
	WS_TRACK_LIST_MEM_REF_INC: 'WS_TRACK_LIST/MEM_REF_INC',
	WS_TRACK_LIST_MEM_REF_DEC: 'WS_TRACK_LIST/MEM_REF_DEC',

}

export default actionTypes;