import store from '../store'

//const localizedString = (store != null && store.getState().desktopUI != null) ? store.getState().desktopUI.localizedString : {}

//Mock abort controller in tests
const AbortController = (global.AbortController != null) ? global.AbortController : function () { this.signal = undefined; this.abort = x => x }

function restFetchHelper(serverPoolId, wsPath, method, restUrl, restData, signal) {
  if (typeof global.mock !== 'undefined' && typeof global.mock.webserviceFetch !== 'undefined') {
    return global.mock.webserviceFetch(serverPoolId, wsPath, restData)
  } else {
    //serverPoolId = 3965
    let externalServerUrl = (store != null && store.getState().webservice != null) ? store.getState().webservice.externalServerUrl : null
    let fetchAddress = "https://fotelikirest.globautomotive.com/api/"
    

    return fetch(fetchAddress + restUrl, {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      method,
      body: (method !== "GET") ? JSON.stringify(restData) : null,
      credentials: 'include',
      signal
    })
      .then(response => {
        if (!response.ok) {
          return response.text().then((text) => {
            response.text = text
            return Promise.reject(response)
          })
        }
        /* return response.text().then(text => {
          let responseText = getCorrectResponse(text)
          return Promise.resolve(responseText)
        }) */

        return response.json()
      })
      .catch(response => {
        if (response.status != null) {
          switch (response.status) {
            case 500: response.errorMessage = response.statusText + " (" + response.status + ")"; break;
            default: response.errorMessage = response.text; break;
          }
        }
        if (response.message != null) response.errorMessage = "Brak połączenia z serwerem"
        return Promise.reject(response)
      })
  }
}

export function userLogIn(email, password, serverPoolId, wsPath, mockable = {
  fetch: restFetchHelper,
  returnFetchParams: false,
}) {
  if (serverPoolId == null) {
    const min = 1;
    const max = 5;
    serverPoolId = Math.floor(Math.random() * (max - min + 1)) + min
  }

  const restUrl = "accounts/login"
  const restData = { email, password }

  if (mockable.returnFetchParams === true)
    return { serverPoolId, wsPath, restData }

  const abortController = new AbortController()
  const fetchPromise = () => mockable.fetch(serverPoolId, wsPath, "POST", restUrl, restData, abortController.signal)
    .then((data) => {
      if (typeof data.redirect_server_pool_id === "undefined") {
        return Promise.resolve({
          serverPoolId: serverPoolId,
          data
        });
      } else {
        return Promise.reject({ ...data, message: "Błąd przekierowania" });
      }
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function userLogOut(serverPoolId, wsPath, mockable = {
  fetch: restFetchHelper,
  returnFetchParams: false,
}) {

  const restUrl = "accounts/logout"

  if (mockable.returnFetchParams === true)
    return { serverPoolId, wsPath, fetchParams: null }

  const abortController = new AbortController()
  const fetchPromise = () => mockable.fetch(serverPoolId, wsPath, "POST", restUrl, null, abortController.signal)
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function rightsList(userId, serverPoolId, wsPath, mockable = {
  fetch: restFetchHelper,
  returnFetchParams: false,
}) {

  const restUrl = "rights/" + userId

  if (mockable.returnFetchParams === true)
    return { serverPoolId, wsPath, fetchParams: null }

  const abortController = new AbortController()
  const fetchPromise = () => mockable.fetch(serverPoolId, wsPath, "GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function userRemindPassword(username, sendto, serverPoolId, wsPath, mockable = {
  fetch: restFetchHelper,
  returnFetchParams: false,
}) {

  const restUrl = "users/reminds/"
  const restData = { username, sendto }

  if (mockable.returnFetchParams === true)
    return { serverPoolId, wsPath, restData }

  const abortController = new AbortController()
  const fetchPromise = () => mockable.fetch(serverPoolId, wsPath, "POST", restUrl, restData, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function userLoggedIn(serverPoolId, wsPath, mockable = {
  fetch: restFetchHelper,
  returnFetchParams: false,
}) {

  const restUrl = "users/logged/in"

  if (mockable.returnFetchParams === true)
    return { serverPoolId, wsPath, fetchParams: null }

  const abortController = new AbortController()
  const fetchPromise = () => mockable.fetch(serverPoolId, wsPath, "GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function userInfo(serverPoolId, wsPath, mockable = {
  fetch: restFetchHelper,
  returnFetchParams: false,
}) {

  const restUrl = "users/info"

  if (mockable.returnFetchParams === true)
    return { serverPoolId, wsPath, fetchParams: null }

  const abortController = new AbortController()
  const fetchPromise = () => mockable.fetch(serverPoolId, wsPath, "GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function accountList(serverPoolId, wsPath, mockable = {
  fetch: restFetchHelper,
  returnFetchParams: false,
}) {

  const restUrl = "accounts/"

  if (mockable.returnFetchParams === true)
    return { serverPoolId, wsPath }

  const abortController = new AbortController()
  const fetchPromise = () => mockable.fetch(serverPoolId, wsPath, "GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}

export function trackList(serverPoolId, wsPath, mockable = {
  fetch: restFetchHelper,
  returnFetchParams: false,
}) {

  const restUrl = "trips/"

  if (mockable.returnFetchParams === true)
    return { serverPoolId, wsPath }

  const abortController = new AbortController()
  const fetchPromise = () => mockable.fetch(serverPoolId, wsPath, "GET", restUrl, null, abortController.signal)
    .then((rows) => {
      return Promise.resolve(rows);
    })
    .catch((err) => {
      return Promise.reject(err);
    })

  return {
    promise: fetchPromise,
    abort: abortController.abort.bind(abortController)
  }
}