import actionTypes from '../actions/_actionTypes.js'
import {
	fetchReducer, fetchReducerRejected, fetchReducerFulfilled,
	fetchReducerErrorHide
} from './webserviceFetchReducer'
import webserviceFetchArrayReducer from './webserviceFetchArrayReducer'

export const initialState = {
	serverPoolId: null,
	loggedIn: false,
	loggingOut: false,
	currentTimestamp: null,
	sessionId: "",
	externalServerUrl: null,
	userData: [],
	userInfo: [],
	userLoggedIn: [],
	userRemindPassword: [],
	accountList: [],
	trackList: []
}

export default function reducer(state = initialState, action) {

	const split = action.type.split("/");
	const action_type = split[0];
	// const subaction_type = split[1];

	switch (action_type) {
		case actionTypes.WS_USER_LOG_IN: {
			const userData = { ...fetchReducer(state.userData, action) }
			return { ...state, userData, loggedIn: false }
		}
		case actionTypes.WS_USER_LOG_IN_REJECTED: {
			const userData = { ...fetchReducerRejected(state.userData, action) }
			return { ...state, userData, loggedIn: false }
		}
		case actionTypes.WS_USER_LOG_IN_FULFILLED: {
			const userData = { ...fetchReducerFulfilled(state.userData, action) }
			const serverPoolId = action.payload.serverPoolId
			return { ...state, userData, serverPoolId, loggedIn: true }
		}
		case actionTypes.WS_USER_LOG_IN_ERROR_HIDE: {
			const userData = { ...fetchReducerErrorHide(state.userData, action) }
			return { ...state, userData, loggedIn: true }
		}
		case actionTypes.WS_USER_LOGGED_IN: {
			const { userLoggedIn } = state
			const newUserLoggedIn = webserviceFetchArrayReducer(userLoggedIn, action)
			return { ...state, userLoggedIn: newUserLoggedIn, loggedIn: newUserLoggedIn[0].data }
		}
		case actionTypes.WS_USER_LOG_OUT: {
			const userData = { ...fetchReducer(state.userData, action) }
			return { ...state, userData, loggingOut: true }
		}
		case actionTypes.WS_USER_LOG_OUT_REJECTED: {
			const userData = { ...fetchReducerRejected(state.userData, action) }
			return { ...state, userData, loggedIn: true, loggingOut: false }
		}
		case actionTypes.WS_USER_LOG_OUT_FULFILLED: {
			return { ...initialState, sessionId: state.sessionId, loggingOut: true, loggedIn: false }
		}
		case actionTypes.WS_USER_REMIND_PASSWORD: {
			const { userRemindPassword } = state
			const newUserRemindPassword = webserviceFetchArrayReducer(userRemindPassword, action)
			return { ...state, userRemindPassword: newUserRemindPassword }
		}

		case actionTypes.WS_SET_WSPATH_AND_SP: {
			const { wsPath, serverPoolId } = action.payload
			return { ...state, wsPath, serverPoolId }
		}

		case actionTypes.WS_SET_SESSION_ID: {
			const { sessionId } = action.payload
			return { ...state, sessionId }
		}
		case actionTypes.WS_SET_EXTERNAL_SERVER: {
			const { externalServerUrl } = action.payload
			return { ...state, externalServerUrl }
		}
		case actionTypes.WS_USER_INFO: {
			const { userInfo } = state
			const newUserInfo = webserviceFetchArrayReducer(userInfo, action)
			return { ...state, userInfo: newUserInfo }
		}

		case actionTypes.WS_ACCOUNT_LIST: {
			const { accountList } = state
			const newAccountList = webserviceFetchArrayReducer(accountList, action)
			return { ...state, accountList: newAccountList }
		}
		case actionTypes.WS_TRACK_LIST: {
			const { trackList } = state
			const newTrackList = webserviceFetchArrayReducer(trackList, action)
			return { ...state, trackList: newTrackList }
		}
		case actionTypes.WS_RIGHTS_SAVE: {
			const { rightsSave } = state
			const newRightsSave = webserviceFetchArrayReducer(rightsSave, action)
			return { ...state, rightsSave: newRightsSave }
		}
		case actionTypes.WS_OBJECT_LIST: {
			const { objectList } = state
			const newObjectList = webserviceFetchArrayReducer(objectList, action)
			return { ...state, objectList: newObjectList }
		}
		
		default:
			return state;
	}
}