import { call, put, select, takeEvery } from 'redux-saga/effects'
import actionType from '../actions/_actionTypes.js'
import * as ws from '../api/webservice.js'
import * as actions from '../actions/webserviceActions.js'
import { takeFirstPerKey_CancelOnDifferentParams, takeFirst_CancelOnDifferentParams, takeFirst, shouldFetchData } from '../utils/saga.js'
//import { toast } from 'react-toastify'

export const getServerPoolId = (state) => state.webservice.serverPoolId;
const getLoggedIn = (state) => state.webservice.loggedIn;
export const getWsPath = (state) => state.webservice.wsPath;
export const getExternalServerUrl = (state) => state.webservice.externalServerUrl;
//const getLocalizedString = (state) => state.desktopUI.localizedString

//TODO: Sprawdzać czy użytkownik jest zalogowany, i zwracać błąd gdy nie jest.
//TODO: Dodaj timeout i anulowanie wywołań.
//TODO: Dodaj debounce dla objectList i HistoryList

//TODO: Sprawdz czy gdy wywolasz logowanie ponownie z innym loginem i hasłem w trakcie logowania, czy poprzednie requesty zostaną abortowane z jednym blokiem finally?
export function* wsUserLogIn(action) {
  let wsPath = yield select(getWsPath);
  let abortableFetch;
  try {
    abortableFetch = ws.userLogIn(action.payload.email, action.payload.password, action.payload.serverPoolId, wsPath)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsUserLogInFulfilled(data.data, data.serverPoolId));
    //yield put(actions.wsAccountList({ id: "global" }))
  } catch (err) {
    if (typeof err.redirect_server_pool_id === "undefined") {
      yield put(actions.wsUserLogInRejected(err));
      //toast.error(err.errorMessage)
    } else {
      try {
        abortableFetch = ws.userLogIn(action.payload.email, action.payload.password, err.redirect_server_pool_id, wsPath)
        const data = yield call(abortableFetch.promise);
        yield put(actions.wsUserLogInFulfilled(data.data, data.serverPoolId));
        yield put(actions.wsUserLoggedIn({id: "global"}))
      } catch (err) {
        yield put(actions.wsUserLogInRejected(err));
        //toast.error(err.errorMessage)
      }
    }
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsUserLogOut() {
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    let wsPath = yield select(getWsPath);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("logged in false")
    }
    let serverPoolId = yield select(getServerPoolId);
    abortableFetch = ws.userLogOut(serverPoolId, wsPath)
    const data = yield call(abortableFetch.promise);
    //yield put(actions.wsUserLoggedInFulfilled({ id: "global" }, false))
    yield put(actions.wsUserLogOutFulfilled(data));
    /* if (window.location.origin === oemPageUrl) {
      window.location = oemPageUrl
    } else if (window.location.origin === navtechPageUrl) {
      window.location = navtechPageUrl
    } else {
      window.location = loginPageUrl
    } */
  } catch (err) {
    yield put(actions.wsUserLogOutRejected(err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsUserLoggedIn(action) {
  const { key } = action.payload
  let abortableFetch;
  try {
    let wsPath = yield select(getWsPath);
    let serverPoolId = yield select(getServerPoolId);
    abortableFetch = ws.userLoggedIn(serverPoolId, wsPath)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsUserLoggedInFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsUserLoggedInRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsUserRemindPassword(action) {
  const { key, username, sendto } = action.payload
  let abortableFetch;
  try {
    abortableFetch = ws.userRemindPassword(username, sendto, 1, "WS1234")
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsUserRemindPasswordFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsUserRemindPasswordRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsSetWsPathAndSP(action) {
  const { wsPath, serverPoolId } = action.payload
  let abortableFetch;
  try {
    yield put(actions.wsSetWsPathAndSPFulfilled(wsPath, serverPoolId));
  } catch (err) {
    yield put(actions.wsSetWsPathAndSPRejected(err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsSetSessionId(action) {
  const { sessionId } = action.payload
  let abortableFetch;
  try {
    yield put(actions.wsSetSessionIdFulfilled(sessionId));
  } catch (err) {
    yield put(actions.wsSetSessionIdRejected(err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsSetExternalServer(action) {
  const { externalServerUrl } = action.payload
  let abortableFetch;
  try {
    yield put(actions.wsSetExternalServerFulfilled(externalServerUrl));
  } catch (err) {
    yield put(actions.wsSetExternalServerRejected(err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsUserInfo(action) {
  const { key } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    let wsPath = yield select(getWsPath);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("logged in false")
    }
    let serverPoolId = yield select(getServerPoolId);
    abortableFetch = ws.userInfo(serverPoolId, wsPath)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsUserInfoFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsUserInfoRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsAccountList(action) {
  const { key } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    let wsPath = yield select(getWsPath);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    let serverPoolId = yield select(getServerPoolId);
    abortableFetch = ws.accountList(serverPoolId, wsPath)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsAccountListFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsAccountListRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsTrackList(action) {
  const { key } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    let wsPath = yield select(getWsPath);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    let serverPoolId = yield select(getServerPoolId);
    abortableFetch = ws.trackList(serverPoolId, wsPath)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsTrackListFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsTrackListRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

/* export function* wsRightsList(action) {
  const { key, userId } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    let wsPath = yield select(getWsPath);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    let serverPoolId = yield select(getServerPoolId);
    abortableFetch = ws.rightsList(userId, serverPoolId, wsPath)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsRightsListFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsRightsListRejected(key, err));
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
}

export function* wsRightsSave(action) {
  const { key, userId, rights, objects, areas, tourPlans } = action.payload
  let abortableFetch;
  try {
    let loggedIn = yield select(getLoggedIn);
    let wsPath = yield select(getWsPath);
    //let localizedString = yield select(getLocalizedString)
    if (loggedIn === false) {
      throw new Error("localizedString.loggedOutLogin")
    }
    let serverPoolId = yield select(getServerPoolId);
    abortableFetch = ws.rightsSave(userId, rights, objects, areas, tourPlans, serverPoolId, wsPath)
    const data = yield call(abortableFetch.promise);
    yield put(actions.wsRightsSaveFulfilled(key, data));
  } catch (err) {
    yield put(actions.wsRightsSaveRejected(key, err));
    toast.error(err.errorMessage)
  } finally {
    if (abortableFetch != null) abortableFetch.abort();
  }
} */

export default function* watchWebserviceSaga() {
  yield takeFirst_CancelOnDifferentParams(actionType.WS_USER_LOG_IN, wsUserLogIn)
  yield takeFirst_CancelOnDifferentParams(actionType.WS_USER_LOG_OUT, wsUserLogOut)
  yield takeFirst_CancelOnDifferentParams(actionType.WS_USER_INFO, wsUserInfo)
  yield takeFirst_CancelOnDifferentParams(actionType.WS_USER_REMIND_PASSWORD, wsUserRemindPassword)
  yield takeFirst_CancelOnDifferentParams(actionType.WS_SET_WSPATH_AND_SP, wsSetWsPathAndSP)
  yield takeFirst_CancelOnDifferentParams(actionType.WS_SET_SESSION_ID, wsSetSessionId)
  yield takeFirst_CancelOnDifferentParams(actionType.WS_SET_EXTERNAL_SERVER, wsSetExternalServer)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_USER_LOGGED_IN, wsUserLoggedIn)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_ACCOUNT_LIST, wsAccountList)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_TRACK_LIST, wsTrackList)
 /*  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_RIGHTS_LIST, wsRightsList)
  yield takeFirstPerKey_CancelOnDifferentParams(actionType.WS_RIGHTS_SAVE, wsRightsSave) */
}