// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TrackTableRow from "components/Tables/TrackTableRow";
import { passedTimeToString } from "utils/helpers";
import React from "react";
import moment from 'moment'
import {useSelector} from 'react-redux'
//import store from '../../store'
import { fetchIsFulfilled } from "utils/fetchStateSelector";

function Tracks() {
  const captions = ["#", "Początek trasy", "Długość trasy", "Długość snu", "Długość aktywności"]
  const storeTracksList = useSelector(store => store.webservice.trackList)
  let trackList = []
  let trackListFetch = null
  if(storeTracksList != null && storeTracksList.length){
    trackListFetch = storeTracksList[0]
    if(fetchIsFulfilled(trackListFetch)){
      trackList = trackListFetch.data
    }
  }
  //let trackList = [{trip_stop_time: 1702127488, trip_start_time: 1702127188, sleep_time: 123, activity_time: 222}]
  console.log(trackList)
  let rows = []
  /* if(trackList.length){
    trackList.forEach((track, index) => {
      let trackTime = passedTimeToString(track.trip_stop_time-track.trip_start_time)
      let sleepTime = passedTimeToString(track.sleep_time)
      let trackStartTime = moment(track.trip_start_time).format("YYYY-MM-DD HH:mm:ss")
      rows.push(<CTableRow>
        <CTableHeaderCell scope="row">{index+1}</CTableHeaderCell>
        <CTableDataCell>{trackStartTime}</CTableDataCell>
        <CTableDataCell>{trackTime}</CTableDataCell>
        <CTableDataCell>{sleepTime}</CTableDataCell>
      </CTableRow>)
    })
  } */
  let trackTime = null
  let sleepTime = null
  let trackStartTime = null
  let activityTime = null
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Card my='22px' overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Flex direction='column'>
          <Text fontSize='lg'/*  color={textColor} */ fontWeight='bold' pb='.5rem'>
            {"Trasy"}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        <Table variant='simple' /* color={textColor} */>
          <Thead>
            <Tr my='.8rem' pl='0px'>
              {captions.map((caption, idx) => {
                return (
                  <Th color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {trackList.length ? trackList.map((track, idx) => {
              trackTime = passedTimeToString(track.trip_stop_time-track.trip_start_time)
              sleepTime = passedTimeToString(track.sleep_time)
              trackStartTime = moment(track.trip_start_time*1000).format("YYYY-MM-DD HH:mm:ss")
              activityTime = passedTimeToString(track.trip_stop_time-track.trip_start_time-track.sleep_time)
              return (
                <TrackTableRow
                  lp={idx+1}
                  key={track.trip_start_time+"."+idx}
                  trackTime={trackTime}
                  date={trackStartTime}
                  sleepTime={sleepTime}
                  activityTime={activityTime}
                />
              );
            }) : <Text align={"center"} width={"100%"} marginTop={10}>Brak tras do wyświetlenia</Text>}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
    </Flex>
  );
}

export default Tracks;
