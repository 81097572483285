/*eslint no-loop-func: off*/

import { fork, take, call, actionChannel, flush } from 'redux-saga/effects'
import _isEqual from 'lodash/isEqual'

export const takeFirst = (pattern, saga, ...args) => fork(function* () {
  while(true) {
    const action = yield take(pattern)
    yield call(saga, ...args.concat(action))
  }
})



export const takeFirstPerKey = (pattern, saga, ...args) => fork(function*() {
  let tasksInProgress = [];
  while (true) {
    const action = yield take(pattern)
    yield fork(function*() {
      const { key } = action.payload
      const keyFound = (tasksInProgress.findIndex(x => x.id === key.id && x.windowId === key.windowId && x.tabId === key.tabId) !== -1)
      if (!keyFound) {
        tasksInProgress.push(key)
        yield call(saga, ...args.concat(action))
        tasksInProgress = tasksInProgress.filter(x => !(x.id === key.id && x.windowId === key.windowId && x.tabId === key.tabId))
      }
    })
  }
})

export const takeFirst_CancelOnDifferentParams = (pattern, saga, ...args) => fork(function*() {
  let taskInProgress = null;
  while (true) {
    const action = yield take(pattern)
    let newAction = {}
    if(pattern.substr(0, 5) === "WSOCK" || pattern.substr(0, 3) === "BDO" || pattern === "WS_SET_SESSION_ID"){
      newAction = {...action}
    } else {
      //newAction = getUrlEncodedPayload(action)
      newAction = {...action}
    }
    yield fork(function*() {
      if (taskInProgress !== null) { // if key already exists
        if (_isEqual(newAction.payload, taskInProgress.payload)) { // if fetch arguments are the same then return
          return
        } else { // if fetch arguments are diffrent then cancel previous fetch and start new one
          // console.log(tasksInProgress[index].payload.dateFrom, "ABORTED")
          taskInProgress.task.cancel()
        }
      }

      // console.log(tasksInProgress)
      let task = yield fork(function*() {
        try {
          // console.log(action.payload.dateFrom, "TASK STARTED")
          yield call(saga, ...args.concat(newAction))
          // console.log(action.payload.dateFrom, "TASK FINISHED")
        } finally {
          // console.log(action.payload.dateFrom, "TASK FINALLY")
          taskInProgress = null;
          // console.log(tasksInProgress)
        }
      })
      taskInProgress = {payload: newAction.payload, task}
    })
  }
})


export const takeFirstPerKey_CancelOnDifferentParams = (pattern, saga, ...args) => fork(function*() {
  let tasksInProgress = [];
  while (true) {
    const action = yield take(pattern)
    let newAction = {}
    if(pattern.substr(0, 5) === "WSOCK" || pattern.substr(0, 5) === "ZO_WS" || pattern.substr(0, 3) === "BDO" || pattern === "WS_SET_SESSION_ID"){
      newAction = {...action}
    } else {
      //newAction = getUrlEncodedPayload(action)
      newAction = {...action}
    }
    yield fork(function*() {
      const { key } = newAction.payload
      const index = tasksInProgress.findIndex(x => x.payload.key.id === key.id && x.payload.key.windowId === key.windowId && x.payload.key.tabId === key.tabId)

      if (index !== -1) { // if key already exists
        if (_isEqual(newAction.payload, tasksInProgress[index].payload)) { // if fetch arguments are the same then return
          return
        } else { // if fetch arguments are diffrent then cancel previous fetch and start new one
          // console.log(tasksInProgress[index].payload.dateFrom, "ABORTED")
          tasksInProgress[index].task.cancel()
        }
      }

      // console.log(tasksInProgress)
      let task = yield fork(function*() {
        try {
          // console.log(action.payload.dateFrom, "TASK STARTED")
          yield call(saga, ...args.concat(newAction))
          // console.log(action.payload.dateFrom, "TASK FINISHED")
        } finally {
          // console.log(action.payload.dateFrom, "TASK FINALLY")
          tasksInProgress = tasksInProgress.filter(x => !(x.payload.key.id === key.id && x.payload.key.windowId === key.windowId && x.payload.key.tabId === key.tabId))
          // console.log(tasksInProgress)
        }
      })
      tasksInProgress.push({payload: newAction.payload, task})
    })
  }
})

export const takeLatestAndWaitPrevious = (pattern, saga, ...args) => fork(function* () {
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel(pattern)

  while (true) {

    const actions = yield flush(requestChan)
    // console.log(actions)

    //TODO: przepelnienie bufora powyzej 10 elementow https://github.com/redux-saga/redux-saga/issues/1027


    // 2- take from the channel
    let action;
    if (actions.length) {
      action = actions[actions.length-1]
    } else {
      action = yield take(requestChan)
    }
    // 3- Note that we're using a blocking call



    // console.log(action, action2)

    yield call(saga, ...args.concat(action))
  }
});

export function shouldFetchData(key, storeData){
  if (storeData != null && storeData.length) {
    storeData = storeData.find(x => JSON.stringify(x.key) === JSON.stringify(key) && x.fetched === true)
    if (storeData != null) {
      return false;
    }
  }
  return true;
}