// import
import Dashboard from "views/Dashboard/Dashboard";
import Tables from "views/Dashboard/Tables";
import Tracks from "views/Tracks/Tracks";
import Billing from "views/Dashboard/Billing";
import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";
import ChangePassword from "views/Auth/ChangePassword";

var dashRoutes = [
  /* {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    element: <Dashboard />,
    layout: "/account",
  }, */
  /* {
    path: "/tables",
    name: "Tables",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: Tables,
    layout: "/admin",
  }, */
  {
    path: "/tracks",
    name: "Trasy",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: Tracks,
    element: <Tracks />,
    layout: "/account",
  },
  /* {
    path: "/billing",
    name: "Billing",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color="inherit" />,
    component: Billing,
    element: <Billing />,
    layout: "/admin",
  }, */
  /* {
    path: "/rtl-support-page",
    name: "RTL",
    rtlName: "آرتيإل",
    icon: <SupportIcon color="inherit" />,
    component: RTLPage,
    layout: "/rtl",
  }, */
  {
    name: "KONTO",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/profile",
        name: "Moje konto",
        rtlName: "لوحة القيادة",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        element: <Profile />,
        layout: "/account",
      },
      {
        path: "/change_password",
        name: "Zmień hasło",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: ChangePassword,
        element: <ChangePassword />,
        layout: "/account",
      },
      /* {
        path: "/signup",
        name: "Sign Up",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
      }, */
    ],
  },
];
export default dashRoutes;
