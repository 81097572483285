import { createInjectSagasStore, sagaMiddleware } from 'redux-sagas-injector';
import { applyMiddleware, combineReducers } from "redux"

import sagas from './sagas'
import { composeWithDevTools } from 'redux-devtools-extension'
import reducers from "./reducers"

import exposeReactToGlobalScope from './utils/exposeReactToGlobalScope'
//import { initExternalAppLoader } from './externalAppLoader'

//const sagaMiddleware = createSagaMiddleware()
const composeEnhancers = composeWithDevTools({
  stateSanitizer: function (state) {
    return {
      ...state
    };
  }
});

const middleware = applyMiddleware(sagaMiddleware);

const store = createInjectSagasStore({ 'rootSaga': sagas }, combineReducers(reducers()), undefined, composeEnhancers(middleware));

exposeReactToGlobalScope(store)
//initExternalAppLoader(store)

export default store