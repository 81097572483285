export function fetchHasData(fetchObject) {
    return fetchObject != null && fetchObject.data != null
  }
  
  export function fetchIsLoading(fetchObject) {
    return fetchObject != null && fetchObject.fetching === true
  }
  
  export function fetchIsFulfilled(fetchObject) {
    return fetchObject != null && fetchObject.data != null && fetchObject.fetching === false && fetchObject.fetched === true
  }
  
  export function fetchIsRejected(fetchObject) {
    return fetchObject != null && fetchObject.error != null && fetchObject.fetching === false && fetchObject.fetched === false
  }
  
  export function fetchIsDone(fetchObject) {
    return fetchObject != null && ((fetchObject.data != null && fetchObject.fetched === true) || (fetchObject.error != null && fetchObject.fetched === false))
  }
  
  export function fetchShouldGetInitData(fetchObject) {
    return fetchObject == null || (fetchObject.data == null && fetchObject.fetching === false)
  }