import React, {useState} from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  ChakraProvider,
  useToast,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/logo.png";
import Footer from "components/Footer/Footer";

function PasswordResetForm() {
  const [email, setEmail] = useState("");
  const toast = useToast()
  const submitHandler = (e) => {
    let fetchAddress = "https://fotelikirest.globautomotive.com/api/"
    fetch(fetchAddress + "accounts/reset_password", {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({email}),
      credentials: 'include'
    })
      .then(async response => {
        if (!response.ok) {
          const text = await response.text();
          response.text = text;
         /*  toast({
            title: 'Błąd!',
            description: text,
            position: 'top',
            status: 'error',
            duration: 5000,
            isClosable: true,
          }) */
          return await Promise.reject(response);
        }
        /* return response.text().then(text => {
          let responseText = getCorrectResponse(text)
          return Promise.resolve(responseText)
        }) */
        toast({
          title: 'Sukces!',
          description: "Wiadomość z linkiem do resetowania hasła została wysłana na podany adres email",
          status: 'success',
          position: 'top',
          duration: 5000,
          isClosable: true,
        })
       /*  setTimeout(() => {
          navigate('/')
        }, 5000) */
      })
      .catch(response => {
        if (response.status != null) {
          switch (response.status) {
            case 500: response.errorMessage = response.statusText + " (" + response.status + ")"; break;
            default: response.errorMessage = JSON.parse(response.text).error; break;
          }
        }
        if (response.message != null) response.errorMessage = "Brak połączenia z serwerem"
        toast({
          title: 'Błąd!',
          description: response.errorMessage,
          status: 'error',
          position: 'top',
          duration: 5000,
          isClosable: true,
        })
        return Promise.reject(response)
      })
    e.preventDefault();
  }

  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  return (

			<Box w='100%'>
				<Box w='100%'>
					<Box  w='100%'>
          <Flex position='relative' mb='40px'>
      <Flex
        h={{ sm: "initial", md: "75vh", lg: "85vh" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        mb='30px'
        pt={{ sm: "100px", md: "0px" }}>
        <form onSubmit={submitHandler} style={{display: "flex", flex: 1, alignItems: "center"}}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: "150px", lg: "80px" }}>
            <Heading color={titleColor} fontSize='32px' mb='10px'>
              Witaj
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'>
              Wprowadź adres email, aby wysłać wiadomość z linkiem do resetowania hasła.
            </Text>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Email
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='24px'
                fontSize='sm'
                type='text'
                placeholder='Twój adres email'
                size='lg'
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <Button
                fontSize='10px'
                type='submit'
                bg='teal.300'
                w='100%'
                h='45'
                mb='20px'
                color='white'
                mt='20px'
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}>
                WYŚLIJ
              </Button>
            </FormControl>
            {/* <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'>
              <Text color={textColor} fontWeight='medium'>
                Don't have an account?
                <Link color={titleColor} as='span' ms='5px' fontWeight='bold'>
                  Sign Up
                </Link>
              </Text>
            </Flex> */}
          </Flex>
        </Flex>
        </form>
        <Box
          display={{ base: "none", md: "block" }}
          overflowX='hidden'
          h='100%'
          w='40vw'
          position='absolute'
          right='0px'>
          <Box
            bgImage={signInImage}
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            borderBottomLeftRadius='20px'></Box>
        </Box>
      </Flex>
    </Flex>
					</Box>
				</Box>
				<Box px='24px' mx='auto' width='1044px' maxW='100%'>
					<Footer />
				</Box>
			</Box>
  );
}

export default PasswordResetForm;
