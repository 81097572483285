// Chakra imports
import { Flex, Grid, useColorModeValue, Button } from "@chakra-ui/react";
import avatar4 from "assets/img/avatars/avatar4.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import React from "react";
import { FaCube, FaPenFancy } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import Conversations from "./components/Conversations";
import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";
import ProfileInformation from "./components/ProfileInformation";
import Projects from "./components/Projects";
import { useSelector } from "react-redux";
import { fetchIsFulfilled } from "utils/fetchStateSelector";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function Profile() {
  const navigate = useNavigate()
  const userLogin = useSelector(store => store.webservice.userData)
  let loginData = {}
  if(fetchIsFulfilled(userLogin)){
    loginData = userLogin.data
  }
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "gray.800"
  );

  return (
    <Flex direction='column' style={{paddingTop: 95}}>
        <ProfileInformation
          title={"Moje konto"}
          name={loginData.name}
          email={loginData.email}
          registerDate={(loginData.register_date != null) ? moment(loginData.register_date).format("YYYY-MM-DD HH:mm:ss") : ""}
        />
       <Button bg={bgButton} color='white' fontSize='xs' variant='no-hover' width={120} style={{marginTop: 20}} onClick={() => navigate("/account/change_password")}>Zmień hasło</Button>
    </Flex>
  );
}

export default Profile;
