import React, {useState} from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
  ChakraProvider,
  useToast
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/logo.png";
import { useNavigate } from "react-router-dom";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Footer from "components/Footer/Footer";
import theme from "theme/theme";
import { fetchIsFulfilled } from "utils/fetchStateSelector";
import { useSelector } from "react-redux";

function ChangePassword() {
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const navigate = useNavigate();
  const toast = useToast();
  const userLogin = useSelector(store => store.webservice.userData)
  const submitHandler = (e) => {
    e.preventDefault();
    if(password !== password2){
      toast({
        title: 'Uwaga!',
        description: "Podane nowe hasła nie są takie same!",
        status: 'warning',
        position: 'top',
        duration: 5000,
        isClosable: true,
      })
      return;
    }
    let email = ""
    if(fetchIsFulfilled(userLogin)){
      email = userLogin.data.email
    }
    let fetchAddress = "https://fotelikirest.globautomotive.com/api/"
    fetch(fetchAddress + "accounts/change_password", {
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify({email, new_password: password, old_password: oldPassword}),
      credentials: 'include'
    })
      .then(async response => {
        if (!response.ok) {
          const text = await response.text();
          response.text = text;
          /* toast({
            title: 'Błąd!',
            description: text,
            status: 'error',
            position: 'top',
            duration: 5000,
            isClosable: true,
          }) */
          return await Promise.reject(response);
        }
        /* return response.text().then(text => {
          let responseText = getCorrectResponse(text)
          return Promise.resolve(responseText)
        }) */
        toast({
          title: 'Sukces!',
          description: "Twoje nowe hasło zostało zapisane.",
          status: 'success',
          position: 'top',
          duration: 5000,
          isClosable: true,
        })
        setTimeout(() => {
          navigate('/account/profile')
        }, 5000)
      })
      .catch(response => {
        if (response.status != null) {
          switch (response.status) {
            case 500: response.errorMessage = response.statusText + " (" + response.status + ")"; break;
            default: response.errorMessage = JSON.parse(response.text).error; break;
          }
        }
        if (response.message != null) response.errorMessage = "Brak połączenia z serwerem"
        toast({
          title: 'Błąd!',
          description: response.errorMessage,
          status: 'error',
          position: 'top',
          duration: 5000,
          isClosable: true,
        })
        return Promise.reject(response)
      })
    e.preventDefault();
  }

  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  return (

    <Card p='16px' mt={{ sm: "75px", xl: "90px" }} mb={{ sm: "24px", xl: "24px" }}>
    <CardHeader p='12px 5px' mb='12px'>
      <Text fontSize='lg' /* color={titleColor} */ fontWeight='bold'>
        Zmiana hasła
      </Text>
    </CardHeader>
    <CardBody px='5px'>
        <form onSubmit={submitHandler} style={{display: "flex", flex: 1, alignItems: "center"}}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            p='48px'
            mt={{ md: "50px", lg: "20px" }}>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'>
              Wprowadź swoje dotychczasowe hasło oraz dwukrotnie wpisz nowe hasło, aby dokonać zmiany.
            </Text>
            <FormControl>
            <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Dotychczasowe hasło
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='24px'
                fontSize='sm'
                type='password'
                placeholder='*****'
                size='lg'
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Nowe hasło
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='24px'
                fontSize='sm'
                type='password'
                placeholder='*****'
                size='lg'
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <FormLabel ms='4px' fontSize='sm' fontWeight='normal'>
                Powtórz nowe hasło
              </FormLabel>
              <Input
                borderRadius='15px'
                mb='24px'
                fontSize='sm'
                type='password'
                placeholder='*****'
                size='lg'
                value={password2}
                onChange={(e) => {
                  setPassword2(e.target.value);
                }}
              />
              <Button
                fontSize='10px'
                type='submit'
                bg='teal.300'
                w='100%'
                h='45'
                mb='20px'
                color='white'
                mt='20px'
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}>
                ZAPISZ
              </Button>
            </FormControl>
            {/* <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'>
              <Text color={textColor} fontWeight='medium'>
                Don't have an account?
                <Link color={titleColor} as='span' ms='5px' fontWeight='bold'>
                  Sign Up
                </Link>
              </Text>
            </Flex> */}
          </Flex>
        </Flex>
        </form>
      </CardBody>
      </Card>
  );
}

export default ChangePassword;
