import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes, Navigate, BrowserRouter } from 'react-router-dom'
import { connect } from "react-redux";
//import './scss/style.scss'
import store from './store'
import { wsAccountList, wsTrackList } from './actions/webserviceActions';
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import Tracks from 'views/Tracks/Tracks';
import Login from 'views/Auth/SignIn';
import PasswordResetForm from 'views/Auth/PasswordResetForm';
import NewPasswordForm from 'views/Auth/NewPasswordForm';
import ChangePassword from 'views/Auth/ChangePassword';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)


// Containers
/* const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout')) */


// Pages
//const Login = React.lazy(() => import('./views/Auth/SignIn'))
/* const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500')) */

class App extends Component {
  componentDidUpdate(){
    if(store != null && store.getState() != null && store.getState().webservice != null && store.getState().webservice.loggedIn){
      /* this.props.dispatch(wsAccountList({id: "global"})) */
      this.props.dispatch(wsTrackList({id: "global"}))
    }
  }

  render() {
    let loggedIn = false
    if(store != null && store.getState() != null && store.getState().webservice != null && store.getState().webservice.loggedIn){
      loggedIn = store.getState().webservice.loggedIn
    }
    console.log("app")
    console.log("loggedIn ", loggedIn)
    return (
      <BrowserRouter>
      <Routes>
      <Route exact path="/login" name="Login Page" element={<Login />} />
      <Route exact path="/reset_password" name="Password Reset" element={<ChakraProvider theme={theme} resetCss={false} w='100%' ><PasswordResetForm /></ChakraProvider>} />
      <Route path="/new_password" name="New Password" element={<ChakraProvider theme={theme} resetCss={false} w='100%' ><NewPasswordForm /></ChakraProvider>} />
     {/*  <Route path="/account/change_password" name="Password Change" element={<ChangePassword />} /> */}
      <Route path='/auth/*' name="Login" element={<AuthLayout />} />
        {/* <Route path={`/auth`} element={AuthLayout} />
        <Route path={"/admin/*"} element={<AdminLayout />} />
        <Route path={`/tracks`} element={<Tracks />} /> */}
        <Route path="*" name="Home" element={loggedIn ? <AdminLayout /> : <AuthLayout />} />
        {/* <Route path="/" name="Home" element={<Navigate to="/admin/dashboard" />} /> */}
        {/* <Navigate from={`/`} to="/admin/dashboard" /> */}
      </Routes>
    </BrowserRouter>
    )
  }
}

App = connect((store, props) => {
	let loggedIn = (store.webservice != null) ? store.webservice.loggedIn : null
	
	return {
		loggedIn
	};
})(App);

export default App
